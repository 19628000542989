.notActive {
  opacity: 0;
  max-height: 0;
}

.active {
  opacity: 1 !important;
  max-height: auto !important;

  transition: all 0.3s ease;
  position: relative;
  z-index: 2;
  margin-bottom: 3rem;
}

.currencyIcon,
.countryFlag {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}

.countryFlag {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}
